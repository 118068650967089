import React, { useEffect, useState } from "react";
import { Table, Container, Spinner } from "react-bootstrap";
import carbonDocument from "../assets/img/carbon_document.png";
import axios from "axios";

const extractInfo = (inputArray) => {
  const pattern = /^(.+)_(Mapped|Unmapped)_(\d{8})\.csv$/;

  return inputArray
    ?.map((str) => {
      const match = str.match(pattern);

      if (match) {
        const [name, , mappedStatus, dateString] = match;
        const status = mappedStatus === "Mapped" ? "Success" : "Processing";

        const formattedDate = `${dateString.substring(
          0,
          2
        )}-${dateString.substring(2, 4)}-${dateString.substring(4)}`;

        return {
          status: status,
          date: formattedDate,
          name: name,
        };
      }
      return null;
    })
    .filter((item) => item !== null);
};

export const FilesTable = ({ filesData, setShowError, setErrorMessage }) => {
  console.log("filesData:", filesData);
  const [files, setFiles] = useState(() => extractInfo(filesData));

  const handleDownload = async (fileName) => {
    const url = `${process.env.REACT_APP_GAME_MATCHING_BASE_URL}/matching_tool/download_csv/${fileName}`;
    try {
      const response = await axios.get(url, {
        responseType: "blob",
        headers: {
          "api-key": `${process.env.REACT_APP_API_KEY}`,
        },
      });

      const file = new Blob([response.data], {
        type: "text/csv",
      });

      const fileURL = URL.createObjectURL(file);

      const link = document.createElement("a");
      link.href = fileURL;
      link.setAttribute("download", `${fileName}`);
      document.body.appendChild(link);
      link.click();

      document.body.removeChild(link);
      URL.revokeObjectURL(fileURL);
    } catch (error) {
      console.log("Error in handleDownload:", error);
      setErrorMessage(`Error in Download file function:${error}`);
      setShowError(true);
    }
  };

  useEffect(() => {
    setFiles(() => extractInfo(filesData));
  }, [filesData]);
  return (
    files.length !== 0 && (
      <Container className="p-0">
        <Table
          bordered
          hover
          responsive
          className="table text-center align-middle"
        >
          <thead>
            <tr>
              <th>Status</th>
              <th>Processed Date</th>
              <th>Name</th>
              <th>Download</th>
            </tr>
          </thead>
          <tbody>
            {files?.slice(0, 30).map((file, index) => (
              <tr
                key={index}
                className={file.status !== "Processing" && "text-success"}
              >
                <td>
                  <span className="mx-2">{file.status}</span>
                  {file.status === "Processing" && (
                    <Spinner
                      animation="border"
                      variant="info"
                      className="custom-spinner"
                    />
                  )}
                </td>
                <td>{file.date}</td>
                <td>{file.name}</td>
                <td>
                  <button
                    className={`download-btn ${
                      file.status === "Processing"
                        ? "download-btn-disabled"
                        : ""
                    }`}
                    variant="outline-primary"
                    onClick={() => handleDownload(file.name)}
                    disabled={file.status === "Processing"}
                  >
                    <img src={carbonDocument} alt="download" /> Download
                  </button>
                </td>
              </tr>
            ))}
          </tbody>
        </Table>
      </Container>
    )
  );
};