import React, { useState, useEffect } from "react";
import { Container, Row, Col, Button, Alert } from "react-bootstrap";
import Navbar from "./Navbar";
import { FilesTable } from "./FilesTable";
import carbonDocument from "../assets/img/carbon_document.png";
import { DropzoneArea } from "material-ui-dropzone";
import CloudUploadIcon from "../assets/img/upload-icon.png";
import axios from "axios";

const errorMessages = {
  400: "Incorrect file! Please re-check the attributes given.",
  409: "Please re-check the file name.",
};

const CustomIcon = () => {
  return <img className="custom-icon" src={CloudUploadIcon} alt="Upload" />;
};

export const HomePage = () => {
  const [files, setFiles] = useState([]);
  const [filesData, setFilesData] = useState([]);
  const [shouldRefetch, setShouldRefetch] = useState(false);
  const [uploadingFiles, setUploadingFiles] = useState([]);
  const [isDisabled, setIsDisabled] = useState(true);
  const [dropzoneKey, setDropzoneKey] = useState(0);
  const [showError, setShowError] = useState(false);
  const [errorMessage, setErrorMessage] = useState("");

  const sampleCsvFileUrl =
    process.env.PUBLIC_URL + "/sample_Unmapped_24012024.csv";

  const resetDropzone = () => {
    setDropzoneKey((prevKey) => prevKey + 1);
  };

  const handleFileChange = (newFiles) => {
    const csvFiles = newFiles.filter(
      (file) =>
        file.type === "text/csv" || file.type === "application/vnd.ms-excel"
    );
    setFiles(csvFiles);
    setIsDisabled(csvFiles.length === 0);
  };

  const uploadFiles = async () => {
    setIsDisabled(true);
    resetDropzone();

    const uploadingFilesInfo = files.map((file) => file.name);
    setUploadingFiles(uploadingFilesInfo);

    const formData = new FormData();

    files.forEach((file) => {
      formData.append("files", file);
    });

    try {
      const response = await axios.post(
        `${process.env.REACT_APP_GAME_MATCHING_BASE_URL}/matching_tool/file_input/`,
        formData,
        {
          headers: {
            "Content-Type": "multipart/form-data",
            "api-key": `${process.env.REACT_APP_API_KEY}`,
          },
        }
      );
      console.log("POST data:", response.data);

      setShouldRefetch(true);
    } catch (error) {
      console.log("ERROR in uploadFiles:", error);
      const defaultMessage = "An unexpected error occurred. Please try again.";
      setErrorMessage(errorMessages[error.response?.status] ?? defaultMessage);
      setShowError(true);
    }

    setUploadingFiles((prevUploadingFiles) =>
      prevUploadingFiles.filter(
        (uploadingFile) => !files.some((f) => f.name === uploadingFile)
      )
    );
    setShouldRefetch(true);
  };

  const handleFetchFiles = async () => {
    try {
      const url = `${process.env.REACT_APP_GAME_MATCHING_BASE_URL}/matching_tool/list_output_files`;
      const response = await axios.get(url, {
        headers: {
          "api-key": `${process.env.REACT_APP_API_KEY}`,
        },
      });
      console.log("GET data:", response.data);
      setFilesData(response.data);
    } catch (error) {
      setErrorMessage("An unexpected error occurred. Please try again.");
      setShowError(true);
      console.error("Error in handleFetchFiles:", error);
    }
  };

  useEffect(() => {
    if (shouldRefetch) {
      handleFetchFiles();
      setShouldRefetch(false);
    }
  }, [shouldRefetch]);

  useEffect(() => {
    handleFetchFiles();

    const intervalId = setInterval(() => {
      handleFetchFiles();
    }, 60000);

    return () => clearInterval(intervalId);
  }, []);

  const combinedFilesData = [
    ...uploadingFiles,
    ...(filesData?.list_of_files ?? []),
  ];

  return (
    <>
      <Navbar />
      {showError && (
        <Alert
          className="alert"
          variant="danger"
          onClose={() => setShowError(false)}
          dismissible
        >
          <Alert.Heading>
            {errorMessage !== errorMessages["400"] ? "Error! " : ""}
            {errorMessage}
          </Alert.Heading>
        </Alert>
      )}
      <Container fluid>
        <Row className="w-100 flex-column justify-content-center align-items-center mx-auto">
          <p className="text-center sort-upload-matched">
            Sort it, Upload it and Matched!
          </p>
          <Col
            sm={12}
            md={6}
            lg={6}
            xl={6}
            className="upload-cont d-flex justify-content-center align-items-start border-0"
          >
            <a
              href={sampleCsvFileUrl}
              download="sample_Unmapped_24012024.csv"
              className="download-btn-sample btn btn-outline-primary"
            >
              <img src={carbonDocument} alt="download" /> Download Sample file
            </a>
            <div className="upload-section">
              <p>Upload</p>
              <p className="dropzone-info">Supported formats: CSV</p>
              <div className="drag-drop-area d-flex flex-column justify-content-center align-items-center">
                <DropzoneArea
                  key={dropzoneKey}
                  className="dropzone"
                  onChange={handleFileChange}
                  acceptedFiles={["text/csv", "application/vnd.ms-excel"]}
                  dropzoneText={
                    <span className="dropzone-msg">
                      <strong>Drag & drop files or</strong>{" "}
                      <span className="underline-text">Browse</span>
                    </span>
                  }
                  filesLimit={3}
                  showPreviewsInDropzone={true}
                  showFileNamesInPreview={true}
                  useChipsForPreview={true}
                  previewText="Supported formats: CSV"
                  Icon={CustomIcon}
                />
                <Button
                  className="btn btn-block"
                  onClick={uploadFiles}
                  disabled={isDisabled}
                >
                  SUBMIT FILES
                </Button>
              </div>
            </div>
          </Col>
          <Col
            sm={12}
            md={6}
            lg={6}
            xl={6}
            className="download-cont d-flex flex-column justify-content-center align-items-center border-0"
          >
            {combinedFilesData.length !== 0 ? (
              <>
                <div className="d-flex w-100 border-0 pb-5">
                  <div className="w-50 text-start">
                    <p className="files-header mb-0">Your Files</p>
                    <p className="sub-text">
                      Your <strong>processing</strong> and
                      <strong> matched</strong> files appear here
                    </p>
                  </div>
                </div>
                <FilesTable
                  filesData={combinedFilesData}
                  setShowError={setShowError}
                  setErrorMessage={setErrorMessage}
                />
              </>
            ) : (
              <>
                <div className="d-flex w-100 border-0">
                  <div className="w-50 text-start">
                    <p className="files-header mb-0">Your Files</p>
                  </div>
                </div>
                <div className="file-display-area d-flex flex-column justify-content-center align-items-center">
                  <p>No files to process</p>
                  <p>Your processing and matched files appear here</p>
                </div>
              </>
            )}
          </Col>
        </Row>
      </Container>
    </>
  );
};