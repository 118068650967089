import React from "react";
import { Container, Row, Col } from "react-bootstrap";
import { ReactComponent as Logo } from "../assets/img/logo-gameopedia.svg";

const Navbar = () => {
  return (
    <Container fluid className="navbar-container">
      <Row className="full-height-row justify-content-center align-items-center">
        <Col xs="auto" className="gop-logo p-0">
          <Logo />
        </Col>

        <Col>
          <p className="game-matching-title">
            <span>Game</span> Matching
          </p>
        </Col>
      </Row>
    </Container>
  );
};

export default Navbar;